/*
  To make checkin work you need to activate ssl when fireing up the app

  run `npm run dev -- --https`

  follow the instructions on https://www.gatsbyjs.org/docs/local-https/ for setting up ssl on localhost
*/

import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getBlockContent, getBlockImage } from "../utils/helpers";

// Layout
import Layout from "../components/layout/Layout";

// Containers
import RenderRichContent from "../components/containers/RenderRichContent";

const NameTicket = ({ data }) => {
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  return (
    <>
      <Helmet>
        <script src={"/js/checkin.js"} />
        <link rel="stylesheet" type="text/css" href={"/styles/checkin.css"} />
      </Helmet>
      <Layout metaInfo={metaStructure}>
        <div
          className="
            flex justify-between items-center
            bg-black text-white
            w-full
            py-8 px-4
            mb-2
          "
        >
          <h1 className="text-2xl w-full md:w-1/4">{pageTitle}</h1>

          {shoutOut && (
            <div
              className="
                transform rotate-4
                bg-white text-black
                border border-black
                max-w-xs
                -mb-16
                p-4
              "
            >
              <p>{shoutOut}</p>
            </div>
          )}
        </div>

        <RenderRichContent content={_rawContent} />

        <div id="checkin_registration">&nbsp;</div>
      </Layout>
    </>
  );
};

export default NameTicket;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "name-ticket" } }) {
      pageTitle
      shoutOut
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
